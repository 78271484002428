import { React } from 'react';
import './category.css';
import { Link } from "react-router-dom";


const Category = ({ imgUrl, category }) => {
  var url = 'https://opentdb.com/api.php?amount=50&category='+category;

  return (
    <div className='quiztopolis__categories-container_category'>
      <div className='quiztopolis__categories-container_category-image'>
        <Link to='/questions' state={url}>
          <img src={imgUrl} alt="categories" />
        </Link>        
      </div>
    </div>
  )
}

export default Category;