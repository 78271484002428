import { React, useContext } from 'react';
import {
    lvl1,
    lvl2,
    lvl3,
    lvl4,
    lvl5,
    lvl6,
    lvl7,
    lvl8,
    lvl9,
    lvl10,
} from "./importimages.js";
import { AuthContext } from "../../context/authContext.js";
import "./playerimage.css"

const PlayerImage = () => {
    const { currentUser } = useContext(AuthContext);

    const levelImages = {
        1: lvl1,
        2: lvl2,
        3: lvl3,
        4: lvl4,
        5: lvl5,
        6: lvl6,
        7: lvl7,
        8: lvl8,
        9: lvl9,
        10: lvl10,
    };

    const levelKeys = Object.keys(levelImages);
    const lastKey = levelKeys[levelKeys.length -1];

    const selectedImage = levelImages[currentUser.level] || levelImages[lastKey];

    return (
        <>
            <img className='playerimg' src={selectedImage} alt="level" />
        </>
    )
}

export default PlayerImage