import React, { useEffect, useState } from 'react';
import './categories.css';
import { Category } from '../../components';
import CustomCategory from "../../components/customcategory/CustomCategory.js";
import SearchBar from '../../components/searchbar/Searchbar.js';
import {
  category01, category02, category03,
  category04, category05, category06,
  category07, category08, category09,
  audiblegif
} from './imports'
import axios from 'axios';

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (query) => {
    setSearchQuery(query);
  }
  const categoryArray = [
    { imgUrl: category01, category: "9" },
    { imgUrl: category02, category: "17" },
    { imgUrl: category03, category: "12" },
    { imgUrl: category04, category: "11" },
    { imgUrl: category05, category: "26" },
    { imgUrl: category06, category: "27" },
    { imgUrl: category07, category: "15" },
    { imgUrl: category08, category: "22" },
    { imgUrl: category09, category: "23" }
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("https://api.quiztopolis.com/api/quizes/getcategories");
        const sortedData = res.data.sort((a, b) => b.id - a.id); // sort in descending order
        setCategories(sortedData);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div className='quiztopolis__categories-add'>
        <a href='https://amzn.to/4d5Xm5w'>
          <img src={audiblegif} alt="GIF" />
        </a>
      </div>
      <div className='quiztopolis__categories section__padding' id='categories'>

        <SearchBar onSearch={handleSearch} />
        <div className='quiztopolis__categories-container'>
          <div className='quiztopolis__categories-container_group'>
            {categories.filter(category => category.category.toLowerCase().includes(searchQuery.toLowerCase())).map((category) => (
              <CustomCategory imgUrl={category.image} category={category.category} />
            ))}
            {categoryArray.filter(category => category.category.toLowerCase().includes(searchQuery.toLowerCase())).map((category, index) => (
              <Category key={index} imgUrl={category.imgUrl} category={category.category} />
            ))}
          </div>
        </div>
      </div>
    </>

  )
}

export default Categories