import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "../../components/navbar/Navbar.jsx";
import './register.css';

const Register = () => {
  const [inputs, setInputs] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [err, setError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [usernameError, setUsernameError] = useState(null);
  const [emailError, setEmailError] = useState(null);

  const navigate = useNavigate();

  /* const validateEmail = (email) => {
    return email.match(/^(([^<>()[\\]\\\\.,;:\\s@\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z0-9_\\-]+\\.)+[a-zA-Z]{2,}))$/);
  }; */

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //so don't refresh page
    if(inputs.username.length < 4 || inputs.username.length > 12) {
      setUsernameError("your username should be between 4 to 12 characteres.");
      return;
    }

    setUsernameError(null);

    if(inputs.password.length < 8 || inputs.username.length > 12) {
      setUsernameError("your password should be between 8 to 12 characteres.");
      return;
    }    

    setPasswordError(null);
    
    try {
      const res = await axios.post("https://api.quiztopolis.com/api/auth/register", inputs);
      /* const res = await axios.post("http://localhost:4000/api/auth/register", inputs); */
      /* console.log(res); */
      navigate("/login");
    } catch (err) {
      console.log("error registering");
      setError(err.response.data);
    }
  };

  return (
    <>
      <div className='gradient__bg'>
        <Navbar />
      </div>
      <div className="quiztopolis__register section__padding">        
        <h1>Register</h1>
        <form>
          <input
            required
            type="text"
            placeholder="username"
            name="username"
            onChange={handleChange}
          />
          <input
            required
            type="email"
            placeholder="email"
            name="email"
            onChange={handleChange}
          />
          <input
            required
            type="password"
            placeholder="password"
            name="password"
            onChange={handleChange}
          />
          <button onClick={handleSubmit}>Register</button>
          {usernameError && <p>{usernameError}</p>}
          {emailError && <p>{emailError}</p>}
          {passwordError && <p>{passwordError}</p>}
          {err && <p>{err}</p>}
          <span>
            Do you have an account? <Link to="/login">Login</Link>
          </span>
        </form>
      </div>
    </>
   
  );
};

export default Register;