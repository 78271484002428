import React from 'react';
import './header.css';
import logo from '../../assets/logo.png'

const Header = () => {
  return (
    <div className='quiztopolis__header section__padding' id='home'>
      <div className='quiztopolis__header-content'>
        <h1 className='gradient__text'>Welcome to QuizTopolis your ultimate destination for free, fun and interactive trivia games!</h1>
        <p>Get ready to dive into a world of educational brain teasers and mind-bending questions that will challenge your knowledge and keep you entertained. 🎉<br/>
        Our quiz games are more than just fun, they’re a competition! As you play, you’ll score points and climb the ranks in our interactive level system. The more you play, the higher your score! 🧠💪<br/>
        But that’s not all! By subscribing, you can keep track of your scores, compete in exclusive challenges, and even learn interesting facts along the way. <br/>
        Are you ready to take on the challenge and show off your trivia prowess? Subscribe now and let the games begin! 🙌</p>
      </div>
      <div className='quiztopolis__header-image'>
        <img src={logo} alt="logo" />
      </div>
    </div>
  )
}

export default Header