import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.js';
import Questions from '../src/pages/questions/Questions.js';
import Register from '../src/pages/register/Register.js';
import Login from '../src/pages/login/Login.js';
import Profile from './pages/profile/Profile.js';
import Tutorial from './pages/tutorial/Tutorial.js';
import Privacypolicy from './pages/privacypolicy/Privacypolicy.js';
import CustomQuestions from './pages/customquestions/CustomQuestions.js';
import './index.css';
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { AuthContexProvider } from "./context/authContext.js";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <AuthContexProvider>
    <Router basename={'/'}>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/questions" element={<Questions />} />
        <Route path="/customquestions" element={<CustomQuestions />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/tutorial" element={<Tutorial />} />
        <Route path="/privacypolicy" element={<Privacypolicy />} />
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  </AuthContexProvider>
);