import React from 'react'
import Footer from '../../containers/footer/Footer.jsx';
import Header from '../../containers/header/Header.jsx';
import Navbar from '../../components/navbar/Navbar.jsx';
import '../../app.css';
import './tutorial.css'

const Tutorial = () => {
    return (
        <div className='App'>
            <div className='gradient__bg'>
                <Navbar />
                {/* <Header /> */}
                <div className='quiztopolis__tutorial section__padding'>
                    <h2 className='gradient__text'>Welcome to QuizTopolis!</h2>
                    <ul>
                        <li>1. Quiz Game: Dive into our educational brain teasers and challenge yourself.</li>
                        <li>2. Competition: Compete against yourself or others to see who can score the highest!</li>
                        <li>3. Free and Fun: Enjoy our quizzes without any cost!</li>
                        <li>4. Interactive Trivia: Learn fascinating facts through our interactive questions.</li>
                        <li>5. Test Your Knowledge: Answer questions to prove your expertise across various topics.</li>
                        <li>6. Lives and Scoring: You start with 3 lives. Lose one for each wrong answer, and if you reach 0 lives, it’s Game Over.</li>
                        <li>7. Question Difficulty:
                            <ul>
                                <li>Easy Questions: Earn 10 points.</li>
                                <li>Medium Questions: Score 20 points.</li>
                                <li>Hard Questions: Prove your expertise and earn 30 points.</li>
                            </ul>
                        </li>
                    </ul>
                    <h3 className='gradient__text' >But wait, there’s more! </h3>
                    <p>To keep your points, create an account with us. It’s easy, and you’ll unlock additional features like tracking your progress and competing with friends.</p>
                    <p>And guess what? We add new quizzes every week! Stay tuned for fresh challenges and exciting content.</p>
                    <p>Get ready to play, learn, and have fun at QuizTopolis! 🎉</p>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Tutorial