import React from 'react'
import Categories from './containers/categories/Categories.jsx';
import Footer from './containers/footer/Footer.jsx';
import Header from './containers/header/Header.jsx';
import Navbar from './components/navbar/Navbar.jsx';
import './app.css';

const App = () => {
  return (
    <div className='App'>
        <div className='gradient__bg'>
            <Navbar />
            <Header />
        </div>
        <Categories />
        <Footer />
    </div>
  )
}

export default App