import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext.js";
import Navbar from "../../components/navbar/Navbar.jsx";
import './profile.css';
import axios from "axios";
import { Buffer } from 'buffer'; 
import PlayerImage from "../../components/playerimage/playerimage.js";

const Profile = () => {
    const { currentUser, setCurrentUser } = useContext(AuthContext);
    const [editing, setEditing] = useState(false); // State to track editing mode
    const [editPass, setEditPass] = useState(false); // State to track editing mode
    const [newEmail, setNewEmail] = useState(currentUser?.email);
    const [newPassword, setNewPassword] = useState("");
    const [err, setError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [playerImage, setPlayerImage] = useState(null);


    const handleEditClick = () => {
        setEditing(true);
    };

    const handleEditPassClick = () => {
        setEditPass(true);
    };

    const handleSaveClick = async (e) => {
        // Perform any necessary validation before saving (e.g., check if the email format is valid)
        // Assuming you have an updateEmail function to save the new email
        // Call your API or update the state accordingly
        // For demonstration purposes, we'll just log the new email here
        if(editing) {
            try {
                const res = await axios.post("https://api.quiztopolis.com/api/auth/editemail", {email: newEmail, username: currentUser.username});
                console.log(res);
                // Create a copy of currentUser and update the email field
                const updatedUser = { ...currentUser, email: newEmail };
                // Set the updated user state
                try {
                    setCurrentUser(updatedUser);
                  } catch (error) {
                    console.error('Error updating user:', error);
                  }
            } catch (err) {
                setError(err.response.data);
            }
    
            // Switch back to non-editing mode
            setEditing(false);
        }else {
            try {
                const res = await axios.post("https://api.quiztopolis.com/api/auth/editpassword", {password: newPassword, username: currentUser.username, email: currentUser.email});
                // Create a copy of currentUser and update the email field
                const updatedUser = { ...currentUser, password: newPassword };
                // Set the updated user state
                try {
                    // Fetch user data or perform other operations
                    // ...
                    setCurrentUser(updatedUser);
                  } catch (error) {
                    console.error('Error updating user:', error);
                  }
            } catch (err) {
                setError(err.response.data);
            }
    
            // Switch back to non-editing mode
            setEditPass(false);
        }
        
    };

    const handleSavePasswordClick = async (e) => {
        if (newPassword.length < 8 || newPassword.length > 12) {
            //your password should be between 8 to 12 characteres 
            setPasswordError("your password should be between 8 to 12 characteres.");
            return;
        }
        try {
            const res = await axios.post("https://api.quiztopolis.com/api/auth/editpassword", {password: newPassword, username: currentUser.username, email: currentUser.email});
            console.log(res);
            // Create a copy of currentUser and update the email field
            const updatedUser = { ...currentUser, password: newPassword };

            // Set the updated user state
            try {
                // Fetch user data or perform other operations
                // ...
                setCurrentUser(updatedUser);
                } catch (error) {
                console.error('Error updating user:', error);
                }
        } catch (err) {
            setError(err.response.data);
        }

        // Switch back to non-editing mode
        setEditPass(false);        
    };

    const handleCancelClick = () => {
        // Reset the input field to the original email
        setNewEmail(currentUser?.email);

        // Switch back to non-editing mode
        setEditing(false);

    };

    const handleCancelPasswordClick = () => {
        setEditPass(false);
    };


    return (
        <>
            <div className='gradient__bg'>
                <Navbar />
            </div>
            <div className="quiztopolis__profile section__padding">
                <div className="quiztopolis__profile-container">
                    <h1>Your Accout</h1>
                    <div className="quiztopolis__profile-photo">
                        <h2>Level: </h2>
                        {/* <img src={{playerImage}} alt="level" />   */}
                        {/* <img src={`../../assets/levels/${currentUser.level}.png`} alt="level" />   */}    
                        <PlayerImage />                       
                    </div>  
                    <span className="quiztopolis__profile-hr-line"></span>
                    <div className="quiztopolis__profile-score">
                        <h2>Total Score:</h2>
                        <p>{currentUser?.score}</p>
                        <span className="quiztopolis__profile-hr-line"></span>
                    </div>
                    <div className="quiztopolis__profile-email">
                        <h2>Email Address</h2>                        
                        {editing ? (
                                <div className="quiztopolis__profile-editing">
                                    <input
                                        type="email"
                                        value={newEmail}
                                        onChange={(e) => setNewEmail(e.target.value)}
                                    />
                                    <div className="quiztopolis__profile-edit">
                                        <div>
                                            <button onClick={handleSaveClick}>Save</button>
                                        </div>
                                        <div>
                                            <button onClick={handleCancelClick}>Cancel</button>
                                        </div>                                        
                                    </div>                                    
                                </div>
                            ) : (
                                <div className="quiztopolis__profile-editing">
                                    <p>{currentUser?.email}</p>
                                    <div className="quiztopolis__profile-edit">
                                        <button onClick={handleEditClick}>Edit</button>
                                    </div>                                    
                                </div>
                        )}                                              
                        <span className="quiztopolis__profile-hr-line"></span>
                    </div>     
                    <div>
                        <h2>Change Password</h2>
                        
                            {editPass ? (
                                    <div className="quiztopolis__profile-editing">
                                        <input
                                            type="password"
                                            value={newPassword}
                                            placeholder="password"
                                            name="password"
                                            onChange={(e) => setNewPassword(e.target.value)}
                                        />
                                        {passwordError && <p>{passwordError}</p>}
                                        <div className="quiztopolis__profile-edit">
                                            <div>
                                                <button onClick={handleSavePasswordClick}>Save</button>
                                            </div>
                                            <div>
                                                <button onClick={handleCancelPasswordClick}>Cancel</button>
                                            </div>   
                                        </div>                                    
                                    </div>
                                ) : (
                                    <div className="quiztopolis__profile-new-password">                                    
                                        <button onClick={handleEditPassClick}>New Password</button>
                                    </div>
                            )}   
                                        
                                        <span className="quiztopolis__profile-hr-line"></span>
                    </div>
                </div>                             
            </div>
        </>
        
    );
};

export default Profile;