import React, { useState } from 'react';
import './searchbar.css'

const SearchBar = ({ onSearch }) => {
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        onSearch(event.target.value);
    }

    return (
        <input className='quiztopolis__search-bar'
            type="text" 
            value={inputValue} 
            onChange={handleInputChange} 
            placeholder="Search..."
        />
    );
}

export default SearchBar;
