import React from 'react';
import { Link } from "react-router-dom";
import './footer.css';
import './socialmedia.css'

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <div className='quiztopolis__footer section__padding'>
      <div className='quiztopolis__footer-heading'>
        <h2 className='gradient__text'>That was fun, right? Now challenge someone else to beat your score.</h2>
      </div>
      <div className='quiztopolis__footer-btn'>
        <p><a href="https://www.paypal.com/donate/?business=7HLC5ZLGPYUGA&no_recurring=0&item_name=Thanks+for+supporting+QuizTopolis%21&currency_code=CAD">SUPPORT US</a></p>
      </div>
      <div className='quiztopolis__footer-links'>
        <div className='quiztopolis__footer-links_logo'>
          <h3>QuizTopolis</h3>
        </div>
        {/* <div className='quiztopolis__footer-links_div'>
            <h4>Links</h4>
            <p>Social Media</p>
            <p>Contact</p>
          </div> */}
      
        <div className='quiztopolis__footer-links_div'>

          <h4>Follow us</h4>
          <ul className="social-icons">
            {/* <li><a href="" className="social-icon"> <i className="fa fa-facebook"></i></a></li> */}
            <li><a href="https://twitter.com/quiztopolis" claclassNamess="social-icon"> <i className="fa fa-twitter"></i></a></li>
            <li><a href="https://www.instagram.com/quiztopolis/" className="social-icon"> <i className="fa fa-instagram"></i></a></li>
            <li><a href="https://www.linkedin.com/in/caiovgoncalves/" className="social-icon"> <i className="fa fa-linkedin"></i></a></li>
            <li><a href="https://github.com/potatoprogrammer" className="social-icon"> <i className="fa fa-github"></i></a></li>
          </ul>

        </div>
        <div className='quiztopolis__footer-links_div'>
          <h4>Get in Touch</h4>
          <p>contact@quiztopolis.com</p>
          <p><Link to="privacypolicy">Privacy Policy</Link></p>
        </div>
      </div>
      <div className='quiztopolis__footer-copyright'>
        <p>© {year} QuizTopolis All rights reserved.</p>
      </div>
    </div>
  )
}

export default Footer