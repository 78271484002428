import React, { useContext, useState} from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import './navbar.css';
import { Link } from "react-router-dom";
import { AuthContext } from '../../context/authContext.js';
import PlayerImage from '../playerimage/playerimage.js';

const Menu = () => (
  <>
    <p><Link to="/">Home</Link></p>
    <p><a href="/#categories">Categories</a></p>
    <p><Link to="tutorial">Tutorial</Link></p>
  </>
)

const Navbar = () => {
  const { currentUser, logout } = useContext(AuthContext);
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className='quiztopolis__navbar'>
      <div className='quiztopolis__navbar-links'>
        <div className='quiztopolis__navbar-links_logo'>
          <Link to="/"><p>QuizTopolis</p></Link>          
        </div>
        <div className='quiztopolis__navbar-links_container'>
          <Menu />
        </div>
      </div>
      <div className='quiztopolis__navbar-sign'>
        {currentUser ? 
          <>
            <p>Level:</p>
            <PlayerImage />
            <p>Total Score: {currentUser?.score}</p> 
            <Link to="/profile"><p>{currentUser?.username}</p></Link>      
            <button type='button' onClick={logout}><Link to="/">Logout</Link></button>
          </>
        :
          <>
            <p><Link to="/login">Sign in</Link></p>
            <button type='button'><Link to="/register">Sign up</Link></button>
          </>
        }
        
        
      </div>
      <div className='quiztopolis__navbar-menu'>
        {toggleMenu
        ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)}/>
        : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)}/>
        }
        {toggleMenu && (
          <div className='quiztopolis__navbar-menu_container scale-up-center'>
            <div className='quiztopolis__navbar-menu_container-links'>
              <Menu />
              <div className='quiztopolis__navbar-menu_container-links-sign'>
                {currentUser ? 
                  <>
                    <Link to="/profile"><p>{currentUser?.username}</p></Link>                  
                    <button type='button' onClick={logout}><Link to="/">Logout</Link></button>                    
                  </>
                :
                  <>
                    <p><Link to="/login">Sign in</Link></p>
                    <button type='button'><Link to="/register">Sign up</Link></button>
                  </>
                }
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar;