import React, { useEffect, useState, useContext } from 'react';
import Navbar from '../../components/navbar/Navbar.jsx';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import {decodeEntities} from '@wordpress/html-entities';
import './questions.css';
import Footer from '../../containers/footer/Footer.jsx';
import Lives from '../../assets/lives.png'
import Coin from '../../assets/coin.png'
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from '../../context/authContext.js';
import PlayerImage from '../../components/playerimage/playerimage.js';

function Questions() {
  const location = useLocation();
  const myurl = location.state;  

  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [lives, setLives] = useState(3);
  const [score, setScore] = useState(0);
  const [color, setColor] = useState();

  const { currentUser, setCurrentUser } = useContext(AuthContext);

  const navigate = useNavigate();
  const [err, setError] = useState(null);

  useEffect(() => {
    window.scrollTo({
      top: 20,
      behavior: 'smooth',
    });
    if (myurl !==''){
      const fetchData = async () => {
        try {        
          const response = await axios.get(myurl);
          const fetchedQuestions = response.data.results.map((question) => {
            return {
              ...question,
              question: decodeEntities(question.question), // Decode the question
              correct_answer: decodeEntities(question.correct_answer), // Decode the correct answer
              incorrect_answers: question.incorrect_answers.map((answer) => decodeEntities(answer)) // Decode the incorrect answers
            };
          }) || [];

          setQuestions(fetchedQuestions);
        
          const allAnswers = fetchedQuestions.map((question) => {
            const incorrectAnswers = question.incorrect_answers;
            const correctAnswer = question.correct_answer;
            const answerset = [...incorrectAnswers, correctAnswer];
            answerset.sort(function(a, b) {
              return Math.random() - 0.5; 
              });
            return [...answerset];
          });         

          setAnswers(allAnswers);
          /* console.log(allAnswers);
          console.log(fetchedQuestions); */
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      //else send the user back to home page somehow
    
      fetchData(); // Call the function when the component mounts
    }
  },[myurl]);

  useEffect(() => {
    if(questions.length > 0){
      if(questions[0].difficulty === "easy"){
        setColor("#40A2E3");
      } else if (questions[0].difficulty === "medium"){
        setColor("#FC6736");
      } else {
        setColor("#B31312");
      }
    }
    
  }, [questions]);

  function updateQuestions(){
    const updatedQuestions = [...questions];
    const updatedAnswers = [...answers]
    updatedQuestions.shift();
    updatedAnswers.shift();
    setQuestions(updatedQuestions);
    setAnswers(updatedAnswers);
  }
  
  function handleAnswerClick(answer) {
    if (answer === questions[0].correct_answer) {
      if(questions[0].difficulty === "easy"){
        setScore(score + 10);
      } else if (questions[0].difficulty === "medium"){
        setScore(score + 20);
      }else {
        setScore(score + 30);
      }      
      updateQuestions();
      if (questions.length <= 1){
        getMoreQuestions();
      }
    } else {
      setLives(lives - 1);
      updateQuestions();
      if (questions.length <= 1){
        getMoreQuestions();
      }
    }
  }; 

  function getMoreQuestions(){
    if (myurl !==''){
      const fetchData = async () => {
        try {        
          const response = await axios.get(myurl);
          const fetchedQuestions = response.data.results.map((question) => {
            return {
              ...question,
              question: decodeEntities(question.question), // Decode the question
              correct_answer: decodeEntities(question.correct_answer), // Decode the correct answer
              incorrect_answers: question.incorrect_answers.map((answer) => decodeEntities(answer)) // Decode the incorrect answers
            };
          }) || [];

          setQuestions(fetchedQuestions);
        
          const allAnswers = fetchedQuestions.map((question) => {
            const incorrectAnswers = question.incorrect_answers;
            const correctAnswer = question.correct_answer;
            const answerset = [...incorrectAnswers, correctAnswer];
            answerset.sort(function(a, b) {
              return Math.random() - 0.5;
            });
            return [...answerset];
          });

          setAnswers(allAnswers);
    
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData(); // Call the function when the component mounts
    }
  }  

  const updateScore = async (e) => {
    if(score > currentUser.score){
      try {
        const res = await axios.post("https://api.quiztopolis.com/api/users/updatescore", {username: currentUser.username, score: score});
        console.log(res);
        
        try {
          console.log("updating user score: " + currentUser.score);
          if(score < (currentUser.level * 100 * 3)){
            const updatedUser = { ...currentUser, score: score };
            setCurrentUser(updatedUser);
          } else {
            const updateUser = {...currentUser,  score:score, level:(Math.floor(score/300))}
            setCurrentUser(updateUser);
          }
          
          console.log("after updating score: " + currentUser.score)
        } catch (error) {
          console.error('Error updating user:', error);
        }
        navigate("/");
      } catch (err) {
        setError(err.response.data);
      }
    }    
  }
  
  /* const updatelevel = async()=>{
    console.log("before updating level: " + score + " " + currentUser.score);
    if(score >= (currentUser.level * 100 * 3) ) {
      try {
        const res = await axios.get("/users/getuserlevel", {username: currentUser.username});
        console.log(res);
        console.log("before: " + currentUser.score + " " + currentUser.level);
        const updatedUserlevel = { ...currentUser, level: (currentUser.level + 1) };
        try {
          console.log("updating user level: " + currentUser.level);
          setCurrentUser(updatedUserlevel);
          console.log("user: " + currentUser.score + " " + currentUser.username + " " + currentUser.level)
        } catch (error) {
          console.error('Error updating user:', error);
        }
        navigate("/");
      } catch (err) {
        setError(err.response.data);
      }
    }
  }
     */
  return (
    <>
      <div className='gradient__bg'>
        <Navbar />              
      </div>
      {(lives > 0) ? 
        <div className='quiztopolis__questions section__padding'>
          {currentUser ? 
              <>
                <div className='quiztopolis__questions-user_total_score'>
                  <p>{currentUser?.username} Total Score: {currentUser?.score}</p>                   
                </div>
                <div className='quiztopolis__questions-player-image'><p>Level: </p><PlayerImage /></div>
              </>                
              :
                null
              }
          <div className='quiztopolis__questions-status'>
              <div className='quiztopolis__questions-lives'>
                <img src={Lives} alt="lives" />
                <p>{lives}</p>
              </div>
              <div className='quiztopolis__questions-difficulty'>
                <h1>Difficulty:</h1>
                <h2 style={{ color }}>{questions.length > 0 ? questions[0].difficulty : null}</h2>
              </div>
              <div className='quiztopolis__questions-score'>
                <img src={Coin} alt="lives" />
                <p>{score}</p>
              </div>
            </div>
          <div className='quiztopolis__questions-question'>           
            <h1>{questions.length > 0 ? questions[0].question : "Loading..."}</h1> 
          </div>
            <div className='quiztopolis__questions-answers' id='answers'>
              {answers.length > 0 ? answers[0].map((answer, index) => (
                <button className='quiztopolis__questions-btn' key={index} onClick={() => handleAnswerClick(answer)}>{answer}</button>
              )) : null}        
            </div>
            <div className='quiztopolis__questions-info'>
                <div>
                  <p><img src={Lives} alt="lives" /> Info:</p>
                  <p>You start with 3 lives, for each wrong answer you lose 1 life, reaching 0 lives its Game Over.</p>
                </div>
                <div>
                  <p><img src={Coin} alt="lives" /> Info:</p>
                  <p>Easy Questions: 10 Points</p>
                  <p>Medium Questions: 20 Points</p>
                  <p>Hard Questions: 30 Points</p>
                </div>
            </div>
        </div>   
      :
      <div className='quiztopolis__questions-lose section__padding'>
        <h1>Sorry, you didn't win this time. But don't worry, you can always try again.</h1>
        <p>Your Total Score Was: {score}</p>
        <Link to='/'><button className='quiztopolis__questions-btn' onClick={currentUser ? updateScore : null}>New Quiz</button></Link>
      </div>}
      <Footer />    
    </>
  )
    
}

export default Questions;