import { React } from 'react';
import '../category/category.css';
import './customcategory.css';
import { Link } from "react-router-dom";


const CustomCategory = ({ imgUrl, category }) => {

  return (
    <div className='quiztopolis__categories-container_category'>
      <div className='quiztopolis__categories-container_category-image'>
        <Link to='/customquestions' state={category}>
          <img src={imgUrl} alt="categories" />      
          <div className='quiztopolis__categories-quiz-name'>
            <p>{category}</p>  
          </div>
            
        </Link>                
      </div>
    </div>
  )
}

export default CustomCategory